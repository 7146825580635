<template lang="pug">
  div(
    v-if="url"
    :class="size"
    v-webp:bg="require('@/assets/' + url)"
  )
</template>

<script>
export default {
  name: 'CustomIcon',
  props: {
    url: {
      type: String,
      default: null
    },
    size: {
      type: String,
      default: 'small'
    }
  }
}
</script>

<style scoped>
.extra-small,
.small,
.medium,
.large {
  display: block;
  margin: auto;
  background-repeat: no-repeat;
  background-position: center;
  -webkit-background-size: contain;
  background-size: contain;
}
.extra-small {
  width: 1.25rem;
  height: 1.25rem;
}
.small {
  width: 3rem;
  height: 3rem;
}
.medium {
  width: 4rem;
  height: 4rem;
  margin: 2rem 0;
}
</style>
